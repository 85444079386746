/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./HorizontalProductListItem.css";

export type HorizontalProductListItemProps = {
  id?: string;
  title?: string;
  subtitle?: string;
  realPrice?: string;
  discountedPrice?: string;
  productImageUrl?: string;
  productImageHeight?: number;
  productImageWidth?: number;
  discount?: string;
  badgeUrls?: string[];
  thumbnailLink?: string
};

export default class HorizontalProductListItem extends React.Component<HorizontalProductListItemProps> {
  render(): React.ReactNode {
    return (
      <a
        id="HorizontalProductListItemContainer"
        href={this?.props?.thumbnailLink ||`/Product/${this.props.id}`}
        target={this?.props?.thumbnailLink ? "_blank" : "_self"} rel="noreferrer"
      >
        <div id="HorizontalProductListItemProductsPhoto">
          <div
            style={{
              width: this.props.productImageWidth,
              height: this.props.productImageHeight,
              overflow: "hidden",
              borderRadius: 8,
            }}
          >
            <img
              src={this.props.productImageUrl}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        </div>
        <div id="HorizontalProductListItemDescription">
          <p id="HorizontalProductListItemTitle">{this.props.title}</p>
          <p id="HorizontalProductListItemSubtitle">{this.props.subtitle}</p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p id="HorizontalProductListItemDiscountedPrice">
              {this.props.realPrice}
            </p>
            <p id="HorizontalProductListItemDiscount">{this.props.discount}</p>
          </div>
          <p id="HorizontalProductListItemPrice">
            {this.props.discountedPrice}
          </p>
          {this.props.badgeUrls?.map((url) => (
            <span style={{ marginRight: 2 }}>
              <img
                alt=""
                style={{ height: 16, objectFit: "cover" }}
                src={url}
              />
            </span>
          ))}
        </div>
      </a>
    );
  }
}
