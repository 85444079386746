export enum Endpoints {
  writeReviewDetail = "mobile/node/Review/WriteReviewDetail",
  addReview = "mobile/node/Review/Add",
  claimCoupon = "mobile/Coupon/Code/Claim",
  bagConfirm = "mobile/node/Bag/Confirm",
  addressAdd = "mobile/node/ShippingAddress/Add",
  addressAddNonLogin = "mobile/node/ShippingAddressDevice/Add",
  searchSellers = "mobile/Search/Sellers",
  searchBrands = "mobile/Search/Brands",
  searchContents = "mobile/node/Search/Contents",
  addressDetail = "mobile/node/ShippingAddress/Detail",
  addressDetailNonLogin = "mobile/node/ShippingAddressDevice/Detail",
  addressList = "mobile/node/ShippingAddress",
  addressListNonLogin = "mobile/node/ShippingAddressDevice",
  addressRemove = "mobile/node/ShippingAddress/Delete",
  addressRemoveNonLogin = "mobile/node/ShippingAddressDevice/Delete",
  addressSelect = "mobile/node/ShippingAddress/Select",
  addressSelectNonLogin = "mobile/node/ShippingAddressDevice/Select",
  addressUpdate = "mobile/node/ShippingAddress/Update",
  addressUpdateNonLogin = "mobile/node/ShippingAddressDevice/Update",
  bag = "mobile/Bag",
  orderDetailV2 = "mobile/node/Order/Detail",
  cancelOrder = "mobile/Order/Cancel",
  bagAdd = "mobile/Bag/Add",
  promoBanner = "mobile/node/Store/PromoBanner",
  bagBilling = "mobile/Bag/Billing",
  bagBillingDevice = "mobile/Bag/BillingDevice",
  bagDelete = "mobile/Bag/Delete",
  bagUpdate = "mobile/Bag/Update",
  bagTotalItem = "mobile/Bag/TotalItems",
  checkoutLocation = "mobile/node/Location/Search",
  checkoutReverseGeoLocation = "mobile/node/Location/GeocodeReverse",
  checkoutBilling = "mobile/node/Checkout/Billing",
  checkoutBillingDevice = "mobile/node/Checkout/BillingDevice",
  checkoutDetail = "mobile/node/Checkout/Detail",
  checkoutDetailDevice = "mobile/node/Checkout/DetailDevice",
  checkoutOrder = "mobile/Checkout/Order/V2",
  checkoutOrderDevice = "mobile/Checkout/OrderDevice/V2",
  citySearch = "mobile/City/Search",
  contentDisplay = "mobile/MultiContentDisplay",
  contentEventDetail = "mobile/Store/contentEventDetail",
  coupons = "mobile/Coupons",
  couponDetail = "mobile/node/Coupon/Detail",
  courierList = "mobile/node/Couriers/List",
  courierListNonLogin = "mobile/node/Couriers/ListDevice",
  directBCA = "mobile/Checkout/Order/DirectBCA/V2",
  directBCADevice = "mobile/Checkout/Order/DirectBCADevice/V2",
  discover = "mobile/node/Content/Discover",
  editProfile = "mobile/MyPage/Main/EditProfile",
  following = "mobile/node/Content/Following",
  followCreator = "mobile/Shop/Follow",
  forgotPassword = "mobile/Account/ForgotPassword",
  likeContent = "mobile/Content/Like",
  loginGoogle = "mobile/Login/Google",
  loginFacebook = "mobile/Login/Facebook",
  loginSso = "mobile/Login/Sso",
  logOut = "mobile/Logout",
  myPage = "mobile/node/MyPage/Main",
  bagBanner = "mobile/node/Bag/Banner",
  point = "mobile/Point",
  orderDetails = "mobile/node/Order/Detail",
  orderReceipt = "mobile/OrderReceipt",
  reorder = "mobile/node/Order/Reorder",
  orderReceiptDevice = "mobile/OrderReceiptDevice",
  orderAndDelivery = "mobile/node/MyPage/OrderList",
  productAttribute = "mobile/Product/Attributes",
  productAttributesV2 = "mobile/node/Product/Attributes",
  productDetail = "mobile/Product/Detail",
  productReadiness = "/mobile/node/Product/Readiness",
  register = "mobile/Account/Register",
  registerPassword = "mobile/Account/RegisterPassword",
  registerProfile = "mobile/Account/RegisterProfile",
  registerVerification = "mobile/Account/Verification",
  resendVerification = "mobile/Account/Resend",
  reusableProps = "mobile/WebReusableProps",
  selectCourier = "mobile/node/Couriers/Select",
  selectCourierNonLogin = "mobile/node/Couriers/SelectDevice",
  shippingAddress = "mobile/ShippingAddress",
  shippingAddressAdd = "mobile/ShippingAddress/Add",
  shippingAddressAddDevice = "mobile/ShippingAddress/AddDevice",
  shippingAddressName = "mobile/Shipping/GetShippingAddress",
  shippingAddressDelete = "mobile/ShippingAddress/Delete",
  shippingAddressDetail = "mobile/ShippingAddress/Detail",
  shippingAddressUpdate = "mobile/ShippingAddress/Update",
  submitRegistrationCreatorFirstPage = "mobile/Seller/SubmitRegistration",
  spaceContents = "mobile/Shop/Contents",
  spaceDetail = "mobile/Shop/Detail",
  spaceProducts = "mobile/Shop/Products",
  trackMyOrder = "mobile/MyPage/TrackMyOrder",
  trackResellersOrder = "/mobile/node/Order/Track",
  AIpreferece = "mobile/node/Preference/Data",
  submitAIpreference = "/mobile/node/Preference/Submit",

  // Store
  brandHome = "mobile/Store/Home/Brands",
  brandProducts = "mobile/node/Store/Brands",
  productOnHype = "mobile/node/Store/Home/OnHypeProducts",
  storeHome = "mobile/node/Store/Home",
  storeHomeMen = "mobile/node/Store/Home/Men",
  storeHomeWomen = "mobile/node/Store/Home/Women",

  // Store V2
  curatedProducts = "mobile/Store/V2/Products",
  getProductFromFeed = "mobile/node/Store/V2/ProductsFromFeed",
  searchProducts = "mobile/node/Search/Products",
  storeMainHome = "mobile/node/Store/V2/Home",
  storeMainProductGroup = "mobile/node/Store/V2/ProductGroupMain",

  // ShowCase
  showCaseHome = "mobile/node/ShowCase/Home",
  showCaseDetail = "mobile/node/ShowCase/Detail",

  // Others
  preloading = "mobile/Preloading",
  toReviewList = "mobile/node/Review/ToReview",
  reviews = "mobile/Product/AllReviews",

  // Payment
  paymentList = "mobile/node/Payment/List",
  paymentListDevice = "mobile/node/Payment/ListDevice",

  // Reseller
  resellerRegister = "mobile/node/Reseller/Register",
  resellerUploadPaymentProof = "/mobile/node/Reseller/Upload/SubscriptionPaymentProof",
  resellerPhoneNumber = "mobile/node/Reseller/PhoneNumber",

  // all category
  allCategory = "/mobile/node/AllCategory",
  allCategoryProductList = "mobile/node/Store/V3/Products",

  // Brands
  topBrands = "mobile/node//Store/V2/TopBrands"
}