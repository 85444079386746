import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ic_arrow_left_black from "../../Assets/Images/ic_arrow_left_black.svg";
import ic_arrow_up from "../../Assets/Images/ic_arrow_up.svg";
import ic_arrow_down from "../../Assets/Images/ic_arrow_down.svg";

interface FilterDrawerProps {
  isVisible: boolean;
  onClose: () => void;
  onApplyFilters: (selectedFilters: SelectedFilters) => void;
  selectedFilters: SelectedFilters;
}

interface FilterOption {
  label: string;
  value: string | number;
}

interface FilterData {
  style: FilterOption[];
  Gender: FilterOption[];
  Age: FilterOption[];
}

interface SelectedFilters {
  style: string[];
  gender: string[];
  age: string[];
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({ isVisible, onClose, onApplyFilters, selectedFilters: externalSelectedFilters }) => {
  const [isRendered, setIsRendered] = useState(false);
  const [filterData, setFilterData] = useState<FilterData | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(externalSelectedFilters);
  const [isStyleOpen, setIsStyleOpen] = useState(true);
  const [isGenderOpen, setIsGenderOpen] = useState(true);
  const [isAgeOpen, setIsAgeOpen] = useState(true);

  useEffect(() => {
    setSelectedFilters(externalSelectedFilters);
  }, [externalSelectedFilters]);

  const hasSelectedFilters = Object.values(selectedFilters).some(
    (filterArray) => filterArray.length > 0
  );

  const handleFilter = () => {
    onApplyFilters(selectedFilters);
    onClose();
  };

  const resetFilters = () => {
    setSelectedFilters({ style: [], gender: [], age: [] });
  };

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => setIsRendered(true), 10);
      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => setIsRendered(false), 600);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        const response = await fetch("https://images.app.thefepi.com/brands/filter.json");
        const data = await response.json();
        setFilterData(data);
      } catch (error) {
        console.error("Failed to fetch filter data:", error);
      }
    };
    fetchFilterData();
  }, []);

  const toggleFilter = (category: keyof SelectedFilters, option: string) => {
    setSelectedFilters((prevFilters) => {
      const updatedCategory = prevFilters[category].includes(option)
        ? prevFilters[category].filter((item) => item !== option)
        : [...prevFilters[category], option];
      return { ...prevFilters, [category]: updatedCategory };
    });
  };

  const toggleAccordion = (setAccordionState: React.Dispatch<React.SetStateAction<boolean>>) => {
    setAccordionState((prev) => !prev);
  };

  const handleClose = () => {
    resetFilters();
    onClose();
  };

  if (!isRendered && !isVisible) return null;

  return (
    <>
      <Overlay isVisible={isVisible} onClick={onClose} />
      <DrawerContainer isVisible={isVisible}>
        <div style={{ padding: 16 }}>
          <Header>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <img src={ic_arrow_left_black} alt="back" onClick={handleClose} />
              <TitleText>Filter</TitleText>
            </div>
            {hasSelectedFilters && <ResetButton onClick={resetFilters}>Reset</ResetButton>}
          </Header>

          {filterData && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <AccordionHeader>
                <SectionTitle>Style</SectionTitle>
                <img src={isStyleOpen ? ic_arrow_down : ic_arrow_up} alt="toggle" onClick={() => toggleAccordion(setIsStyleOpen)}/>
              </AccordionHeader>
              {isStyleOpen && (
                <FilterOptions>
                  {filterData.style.map((option, index) => (
                    <OptionButton
                      key={index}
                      onClick={() => toggleFilter("style", option.label)}
                      selected={selectedFilters.style.includes(option.label)}
                    >
                      {option.label}
                    </OptionButton>
                  ))}
                </FilterOptions>
              )}

              <AccordionHeader >
                <SectionTitle>Gender</SectionTitle>
                <img onClick={() => toggleAccordion(setIsGenderOpen)} src={isGenderOpen ? ic_arrow_down : ic_arrow_up} alt="toggle" />
              </AccordionHeader>
              {isGenderOpen && (
                <FilterOptions>
                  {filterData.Gender.map((option, index) => (
                    <OptionButton
                      key={index}
                      onClick={() => toggleFilter("gender", option.label)}
                      selected={selectedFilters.gender.includes(option.label)}
                    >
                      {option.label}
                    </OptionButton>
                  ))}
                </FilterOptions>
              )}

              <AccordionHeader>
                <SectionTitle>Age</SectionTitle>
                <img onClick={() => toggleAccordion(setIsAgeOpen)} src={isAgeOpen ? ic_arrow_down : ic_arrow_up} alt="toggle" />
              </AccordionHeader>
              {isAgeOpen && (
                <FilterOptions>
                  {filterData.Age.map((option, index) => (
                    <OptionButton
                      key={index}
                      onClick={() => toggleFilter("age", option.label)}
                      selected={selectedFilters.age.includes(option.label)}
                    >
                      {option.label}
                    </OptionButton>
                  ))}
                </FilterOptions>
              )}
            </div>
          )}
        </div>
        <FooterSection>
          <Button onClick={handleFilter}>View Items</Button>
        </FooterSection>
      </DrawerContainer>
    </>
  );
};

export default FilterDrawer;

const FooterSection = styled.div`
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 10px 0px #585b6359;
  padding: 16px;
`;

const Button = styled.button`
  padding: 14px 14px;
  border-radius: 12px;
  background: linear-gradient(65.16deg, #FFC8B4 -8.13%, #FF662F 16.38%, #FF00CE 103.92%);
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
`;

const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 40;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 0.6s ease;
`;

const DrawerContainer = styled.div<{ isVisible: boolean }>`
  max-width: 600px;
  position: fixed;
  display: flex;
  bottom: 0;
  flex-direction: column;
  overflow-y: auto;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 200;
  transform: translateY(${(props) => (props.isVisible ? "0" : "100%")});
  transition: transform 0.6s ease;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 2px;
`;

const TitleText = styled.button`
  font-size: 18px;
  font-weight: bold;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
`;

const ResetButton = styled.button`
  font-size: 14px;
  font-weight: 500;
  color: #894cf5;
  background: none;
  border: none;
  cursor: pointer;
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 16px;
`;

const SectionTitle = styled.h3`
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 8px;
`;

const FilterOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
`;

const OptionButton = styled.button<{ selected?: boolean }>`
  padding: 6px 12px;
  border: ${(props) => (props.selected ? "1px solid transparent" : "1px solid #E4E4E4")};
  border-radius: 20px;
  background: ${(props) =>
    props.selected
      ? "linear-gradient(65.16deg, #FFC8B4 -8.13%, #FF662F 16.38%, #FF00CE 103.92%)"
      : "white"};
  color: ${(props) => (props.selected ? "white" : "black")};
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
`;
